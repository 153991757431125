import { ReactNode } from 'react';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
  useColorModeValue,
  useColorMode,
  Heading,
  Stack,
  Button,
} from '@chakra-ui/react';
import { Link as Scroll} from 'react-scroll';
import { HamburgerIcon, CloseIcon, MoonIcon, SunIcon } from '@chakra-ui/icons';

const Links = ['Home', 'About', 'Skills', 'Qualifications', 'Projects'];

const NavLink = ({ children }: { children: ReactNode }) => (
  <Button
    px={2}
    py={1}
    rounded={'md'}
    bg={'none'}
    _hover={{
      textDecoration: 'none',
      bg: useColorModeValue('#e6e1d7', '#3f3f3f'),
      color: useColorModeValue('', 'grey200'),
    }}> 
    {children}
  </Button>
);

export default function Navbar() {
  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box bg={useColorModeValue('#e9e5dd80', '#34343480')} boxShadow="md" w='100%' position="fixed" css={{ backdropFilter: 'blur(10px)' }} zIndex={2}>
      <Flex h={16} alignItems={'center'} justifyContent={'space-between'} p='2rem'>
          <IconButton
            size={'md'}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={'Open Menu'}
            display={{ md: 'none' }}
            colorScheme={useColorModeValue('blackAlpha', 'orange')}
            onClick={isOpen ? onClose : onOpen}
          />
          <HStack spacing={8} alignItems={'center'}>
            <Box>
              <Heading as="h1" size="md" letterSpacing={'tighter'}>
                Thanos
              </Heading>
            </Box>
            <HStack
              as={'nav'}
              flexGrow={1}
              spacing={4}
              display={{ base: 'none', md: 'flex' }}>
              {Links.map((link,index) => (
                <Scroll key={index} to={link.toLocaleLowerCase()} spy={true} smooth={true}>
                  <NavLink key={link}>{link}</NavLink>
                </Scroll>
              ))}
            </HStack>
          </HStack>
          <IconButton
                aria-label="Toggle theme" onClick={toggleColorMode} colorScheme={useColorModeValue('blackAlpha', 'orange')}>
                {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
          </IconButton>
        </Flex>
        {isOpen ? (
          <Box pb={4} display={{ base: 'inline-block', md: 'none' }}>
            <Stack as={'nav'} spacing={4}>
              {Links.map((link, index) => (
                <Scroll key={index} to={link.toLocaleLowerCase()} spy={true} smooth={true}>
                  <NavLink key={link}>{link}</NavLink>
                </Scroll>
              ))}
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
}