import { Box, useColorModeValue } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { projectsData } from '../../utils/data';
import { IProject } from '../../utils/models';
import { ProjectItem } from '../ProjectItem /ProjectItem';
import './Projects.scss';

export const Projects = () => {
  const [item] = useState({ name: "personal" });
  const [projects, setProjects] = useState<IProject[]>([]);

  useEffect(() => {
    // get projects based on item
    if (item.name === "personal") {
      setProjects(projectsData);
    } else {
      const newProjects = projectsData.filter((project) => {
        return project.demoLink.toLowerCase() === item.name;
      });
      setProjects(newProjects);
    }
  }, [item]);

  return (
    <section className="projects" id="projects">
      <h2 className="projects__title">Projects</h2>
      <span className="projects__subtitle">My Recent Work</span>
      <div className="projects_content">
        {/* projects nav */}
        <div className="projects__filters">
          <Box className="projects__filters-title" color={useColorModeValue('#f9f9f9', '#323232')} bg={useColorModeValue('#0000005c', '#fbd38d')}>
            <span>
              Personal
            </span>
          </Box>
        </div>
        {/* projects */}
        <div className="projects__container">
          {projects.map((item) => {
            return <ProjectItem
             id={item.id}
            title={item.title}
            image={item.image} 
            demoLink={item.demoLink} 
            githubLink={item.githubLink}
            key={item.id}/>
          })}
        </div>
      </div>
    </section>
  )
}
