import { Home } from './component/Home/Home';
import Navbar from './component/Navbar/Navbar';
import { About } from './component/About/About';
import { Skills } from './component/Skills/Skills';
import { Qualifications } from './component/Qualifications/Qualifications';
import { Footer } from './component/Footer/Footer';
import './App.scss'; 
import { Projects } from './component/Projects/Projects';

function App() {
  return (
    <div className="app">
      <header className="app__header">
        <Navbar />
      </header>
      <div className="app__main">
        <Home />
        <About />
        <Skills />
        <Qualifications />
        <Projects />
        <Footer />
      </div>
    </div>
  );
}

export default App;
