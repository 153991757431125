import { Container, Stack, useColorModeValue, Text, VisuallyHidden, chakra, Box } from '@chakra-ui/react'
import React, { ReactNode } from 'react'
import { FaGithub, FaLinkedin, FaMedium } from 'react-icons/fa';

export const Footer = () => {
  const SocialButton = ({
    children,
    label,
    href,
  }: {
    children: ReactNode;
    label: string;
    href: string;
  }) => {
    return (
      <chakra.button
        bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
        rounded={'full'}
        w={8}
        h={8}
        cursor={'pointer'}
        as={'a'}
        href={href}
        target={'_blank'}
        display={'inline-flex'}
        alignItems={'center'}
        justifyContent={'center'}
        transition={'background 0.3s ease'}
        _hover={{
          bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
        }}>
        <VisuallyHidden>{label}</VisuallyHidden>
        {children}
      </chakra.button>
    );
  };
  
  return (
    <Box
    bg={useColorModeValue('#e9e5dd80', '#34343480')} 
    color={useColorModeValue('gray.700', 'gray.200')}>

    <Box
      borderTopWidth={1}
      borderStyle={'solid'}
      borderColor={useColorModeValue('gray.200', 'gray.700')}>
      <Container
        as={Stack}
        maxW={'6xl'}
        py={4}
        direction={{ base: 'column', md: 'row' }}
        spacing={4}
        justify={{ base: 'center', md: 'space-between' }}
        align={{ base: 'center', md: 'center' }}>
        <Text>© 2023 Made with ♥ by Thanos</Text>
        <Stack direction={'row'} spacing={6}>
          <SocialButton label={'Github'} href={'https://github.com/Thanoskaloudis'}>
            <FaGithub />
          </SocialButton>
          <SocialButton label={'Linkedin'} href={'https://www.linkedin.com/in/thanos-kaloudis/'}>
            <FaLinkedin />
          </SocialButton>
          <SocialButton label={'Medium'} href={'https://medium.com/@thanoskaloudis'}>
            <FaMedium />
          </SocialButton>
        </Stack>
      </Container>
    </Box>
  </Box>
  )
}
