import Work1 from "../assets/capture1.png";
import Work2 from "../assets/capture2.png";
import Work3 from "../assets/capture3.png";
import Work4 from "../assets/capture4.png";
import Work5 from "../assets/capture5.png";

export const Group1Lang = [
  {code: "HTML", level: "Advanced"},
  {code: "CSS", level :"Advanced"},
  {code: "JavaScript", level: "Advanced"}
];

export const Group2Lang = [
  {code: "TypeScript", level: "Advanced"},
  {code: "C#", level :"Basic"},
  {code: "Python", level: "Basic"}
];

export const Group1Fram = [
  {code: "React", level: "Advanced"},
  {code: "Vue", level :"Intermediate"},
  {code: "Phaser", level: "Intermediate"}
];

export const Group2Fram = [
  {code: "Redux", level: "Intermediate"},
  {code: "Jest", level :"Intermediate"},
  {code: "Cypress", level: "Basic"}
];



export const projectsData = [
  {
    id: 1,
    image: Work1,
    title: "Employee Polls App",
    demoLink: "https://dancing-kitsune-be85f1.netlify.app/",
    githubLink: "https://github.com/Thanoskaloudis/employee-polls-app"
  },
  {
    id: 2,
    image: Work3,
    title: "Pokemon App",
    demoLink: "https://elaborate-souffle-3e1d9b.netlify.app/",
    githubLink: "https://github.com/Thanoskaloudis/pokemon-app"
  },
  {
    id: 3,
    image: Work2,
    title: "My Reads App",
    demoLink: "https://keen-youtiao-115846.netlify.app/",
    githubLink: "https://github.com/Thanoskaloudis/my-reads"
  },
  {
    id: 4,
    image: Work5,
    title: "Fin Tracker App",
    demoLink: "https://lighthearted-cajeta-a900d1.netlify.app/",
    githubLink: "https://github.com/Thanoskaloudis/fin-tracker"
  },
  {
    id: 5,
    image: Work4,
    title: "Streamy App",
    demoLink: "https://streamy-thanoskaloudis-outlookcom.vercel.app/",
    githubLink: "https://github.com/Thanoskaloudis/streamy"
  },
];