import { CalendarIcon, Icon } from '@chakra-ui/icons';
import { GiSuitcase } from 'react-icons/gi';
import React, { useState } from 'react'
import './Qualifications.scss';

export const Qualifications = () => {
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index: number) => {
    setToggleState(index);
  };

  return (
    <section className="qualifications" id="qualifications">
      <h2 className="qualifications__title">Qualifications</h2>
      <span className="qualifications__subtitle">My personal journey</span>
      <div className="qualifications__container">
        <div className="qualifications__tabs">
          <div
            className={
              toggleState === 1
                ? "qualifications__button qualifications__active"
                : "qualifications__button"
            }
            onClick={() => toggleTab(1)}
          >
          <Icon as={GiSuitcase} className="qualifications__icon"></Icon>
            Education
          </div>

          <div
            className={
              toggleState === 2
                ? "qualifications__button qualifications__active"
                : "qualifications__button"
            }
            onClick={() => toggleTab(2)}
          >
          <Icon as={GiSuitcase} className="qualifications__icon"></Icon>
            Experience
          </div>
        </div>
        <div className="qualifications__sections">
          <div
            className={
              toggleState === 1
                ? "qualifications__content qualifications__content-active"
                : "qualifications__content"
            }
          >
            <div className="qualifications__data">
              <div>
                <h3 className="qualifications__data-title">React</h3>
                <span className="qualifications__data-subtitle">
                  Udacity - Nanodegree
                </span>
                <div className="qualifications__data-calender">
                  <CalendarIcon /> 2022 - 2023
                </div>
              </div>

              <div>
                <span className="qualifications__rounder"></span>
                <span className="qualifications__line"></span>
              </div>
            </div>

            <div className="qualifications__data">
              <div></div>

              <div>
                <span className="qualifications__rounder"></span>
                <span className="qualifications__line"></span>
              </div>

              <div>
                <h3 className="qualifications__data-title">Intermediate JavaScript</h3>
                <span className="qualifications__data-subtitle">
                  Udacity - Nanodegree
                </span>
                <div className="qualifications__data-calender">
                  <CalendarIcon /> 2020 - 2020
                </div>
              </div>
            </div>

            <div className="qualifications__data">
              <div>
                <h3 className="qualifications__data-title">Font End Development</h3>
                <span className="qualifications__data-subtitle">
                  Udacity - Nanodegree
                </span>
                <div className="qualifications__data-calender">
                  <CalendarIcon /> 2018 - 2020
                </div>
              </div>

              <div>
                <span className="qualifications__rounder"></span>
                <span className="qualifications__line"></span>
              </div>
            </div>

            <div className="qualifications__data">
              <div></div>

              <div>
                <span className="qualifications__rounder"></span>
              </div>

              <div>
                <h3 className="qualifications__data-title">Automation Engineering</h3>
                <span className="qualifications__data-subtitle">
                  ATEI Thessaloniki - BEng
                </span>
                <div className="qualifications__data-calender">
                  <CalendarIcon />  2013 - 2019
                </div>
              </div>
            </div>
          </div>

          <div
            className={
              toggleState === 2
                ? "qualifications__content qualifications__content-active"
                : "qualifications__content"
            }
          >
            <div className="qualifications__data">
              <div>
                <h3 className="qualifications__data-title">Front End Developer</h3>
                <span className="qualifications__data-subtitle">
                  Games Global - London
                </span>
                <div className="qualifications__data-calender">
                  <CalendarIcon /> 2022 - Present
                </div>
              </div>

              <div>
                <span className="qualifications__rounder"></span>
                <span className="qualifications__line"></span>
              </div>
            </div>

            <div className="qualifications__data">
              <div></div>

              <div>
                <span className="qualifications__rounder"></span>
                <span className="qualifications__line"></span>
              </div>

              <div>
                <h3 className="qualifications__data-title">Front End Developer</h3>
                <span className="qualifications__data-subtitle">
                  Derivco - London
                </span>
                <div className="qualifications__data-calender">
                  <CalendarIcon /> 2019 - 2022
                </div>
              </div>
            </div>

            <div className="qualifications__data">
              <div>
                <h3 className="qualifications__data-title">Junior Software Developer</h3>
                <span className="qualifications__data-subtitle">Chevin Fleet Solutions - Derby</span>
                <div className="qualifications__data-calender">
                  <CalendarIcon /> 2018 - 2019
                </div>
              </div>

              <div>
                <span className="qualifications__rounder"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
