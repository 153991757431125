import { Box } from '@chakra-ui/layout';
import { HiBadgeCheck } from 'react-icons/hi';
import { useColorModeValue } from '@chakra-ui/react';
import { IScrollDown } from '../../utils/models';
import './SkillsCard.scss';

export const SkillsCard = (props: IScrollDown) => {
  return (
    <Box className="card" color={useColorModeValue('#323232', '#323232')} bg={useColorModeValue('#fefefe', '#fbd38d')}>
      <h3 className="card__title">{props.title}</h3>

      <div className="card__box">
        <div className="card__group">
          {props.group1.map(g => 
            <div key={g.code} className="card__data">
              <HiBadgeCheck />

              <div>
                <h3 className="card__name">{g.code}</h3>
                <span className="card__level">{g.level}</span>
              </div>
            </div>          
          )}
        </div>

        <div className="card__group">
        {props.group2.map(g => 
            <div key={g.code} className="card__data">
              <HiBadgeCheck />

              <div>
                <h3 className="card__name">{g.code}</h3>
                <span className="card__level">{g.level}</span>
              </div>
            </div>          
          )}         
        </div>
      </div>
    </Box>
  )
}
