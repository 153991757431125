import { ArrowForwardIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/layout';
import { Divider, Link, Stack, useColorModeValue } from '@chakra-ui/react';
import { FaGithub } from 'react-icons/fa';
import { IProject } from '../../utils/models';
import './ProjectItem.scss';

export const ProjectItem = (props: IProject) => {
  return (
    
    <Box className="item" key={props.id}  bg={useColorModeValue('#fefefe', '##ffffff29')}>
      <img src={props.image} alt="" className="item__img" />
      <h3 className="item__title">{props.title}</h3>
      <Divider mb="1rem"/>
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
      <Link href={props.demoLink} _hover={{textDecoration: 'none' }} isExternal>  
        Demo <ArrowForwardIcon />
      </Link>
      <Link href={props.githubLink} _hover={{textDecoration: 'none' }} isExternal>  
        <FaGithub />
      </Link>
      </Stack>
    </Box>
  )
}
