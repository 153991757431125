import { Group1Fram, Group1Lang, Group2Fram, Group2Lang } from '../../utils/data';
import { SkillsCard } from '../SkillsCard/SkillsCard';
import './Skills.scss';

export const Skills = () => {
  return (
    <section className="skills" id="skills">
      <h2 className="skills__title">Skills</h2>
      <span className="skills__subtitle">My technical level</span>
      <div className="skills__container">
        <SkillsCard title={"Programming Languages"} group1={Group1Lang} group2={Group2Lang}/>
        <SkillsCard title={"Frameworks & Libraries"} group1={Group1Fram} group2={Group2Fram}/>
      </div>
    </section>
  )
}
