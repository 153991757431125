import { Box, Link, Text } from '@chakra-ui/layout';
import { Button, Icon, useColorModeValue } from '@chakra-ui/react';
import { FaInfoCircle, FaSuitcase, FaCheckSquare } from 'react-icons/fa';
import { HiDocumentText } from 'react-icons/hi';
import CV from '../../assets/CV.pdf';
import './About.scss';

export const About = () => {
  return (
    <section className="about" id="about">
      <h2 className="about__title">About Me</h2>
      <span className="about__subtitle">My introduction</span>
      <div className="about__container">
          <div className="about__data">
            <div className="about__info">
              <Box className="about__info-box" color={useColorModeValue('#323232', '#323232')} bg={useColorModeValue('#fefefe', '#fbd38d')}>
                  <Icon as={FaInfoCircle} w={'1.5rem'} h={'1.5rem'}></Icon>
                  <h3 className="about__data-title">Experience</h3>
                  <span className="about__data-subtitle">4+ Years</span>
              </Box>

              <Box className="about__info-box" color={useColorModeValue('#323232', '#323232')} bg={useColorModeValue('#fefefe', '#fbd38d')}>
                <Icon as={FaCheckSquare} w={'1.5rem'} h={'1.5rem'}></Icon>
                <h3 className="about__data-title">Completed</h3>
                <span className="about__data-subtitle">10+ Projects</span>
              </Box>

              <Box className="about__info-box" color={useColorModeValue('#323232', '#323232')} bg={useColorModeValue('#fefefe', '#fbd38d')}>
                <Icon as={FaSuitcase} w={'1.5rem'} h={'1.5rem'}></Icon>
                <h3 className="about__data-title">Companies</h3>
                <span className="about__data-subtitle">3</span>
              </Box>
            </div>
            <p className="about__description">
            Frontend developer with 4+ years of experience. 👨‍💻 In my current role as a frontend developer,
            I have the opportunity to work with a <Text as='i'>talented</Text> team 
            of <Text as='b'>developers</Text> and <Text as='b'>designers</Text> to bring innovative ideas 💡 to life.
            I am always looking for ways to improve my skills and stay up-to-date on
            the latest technologies and industry trends. 🎯<br/><br/>
            Outside work, I ♥ Traveling, Photography, Video Editing, Gym, and Swimming.
            <br/><br/>
            <Link href={CV} _hover={{textDecoration: 'none'}} download>
              <Button rightIcon={<HiDocumentText />} colorScheme={useColorModeValue('teal', 'orange')} variant='solid' size='lg'>
                  Download CV
              </Button>
            </Link>
            </p>
        </div>
      </div>
    </section>
  )
}

