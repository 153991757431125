import { Button } from '@chakra-ui/button';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { ChatIcon, Icon } from '@chakra-ui/icons';
import { Link } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/react';
import { AiFillGithub, AiFillLinkedin, AiFillMediumSquare } from 'react-icons/ai';
import { ScrollDown } from '../ScrollDown/ScrollDown';
import Profile from '../../assets/profile.png';
import { motion } from 'framer-motion';
import './Home.scss';

export const Home = () => {
  const transition = { duration: 2, type: "spring" };

  return (
    <section className="home" id="home">
      <div className="home__container">
        <div className="home__content">
          <div className="home__social">
            <Image borderRadius='full' src={Profile} alt='profile picture' />
            <Link href="https://github.com/Thanoskaloudis" _hover={{textDecoration: 'none', }} isExternal>
              <Icon as={AiFillGithub} w={'2.2rem'} h={'2.2rem'}></Icon>
            </Link>
            <Link href="https://www.linkedin.com/in/thanos-kaloudis/" _hover={{textDecoration: 'none', }} isExternal>
              <Icon as={AiFillLinkedin} w={'2.2rem'} h={'2.2rem'}></Icon>
            </Link>
            <Link href="https://medium.com/@thanoskaloudis" _hover={{textDecoration: 'none', }} isExternal>
              <Icon as={AiFillMediumSquare} w={'2.2rem'} h={'2.2rem'}></Icon>
            </Link>
          </div>
          <motion.div
            initial={{ x: -200, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={transition}>
            <div className="home__data">
              <h1 className="home__title">Thanos Kaloudis 👋🏼</h1>
              <h3 className="home__subtitle">
                Front End Developer
              </h3>
              <p className="home__description">
                I am a creative developer based in London. I like making <span>fun</span>, <span>interactive</span> things with code. I'm
                passionate about building <span>exceptional</span> digital
                experiences.
              </p>
              <div className="home__description-link">
              <Link href="mailto: thanoskaloudis@outlook.com" _hover={{textDecoration: 'none' }} isExternal>
                <Button rightIcon={<ChatIcon />} colorScheme={useColorModeValue('teal', 'orange')} variant='solid' size='lg'>
                  Say Hello
                </Button>
              </Link>
            </div>
          <ScrollDown />
          </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};
